import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	selector: 'app-card-portfolio',
	templateUrl: './card-portfolio.component.html',
	imports: [RouterModule, TranslateModule],
	styleUrls: ['./card-portfolio.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardPortfolioComponent {
	imgUrl = input<string | null>(null);
	imgTitle = input<string | null>(null);
	title = input<string | null>(null);
	linkUrl = input<string | Array<any> | null | undefined>(undefined);
}
