import {
	Component,
	ElementRef,
	HostListener,
	computed,
	inject,
	untracked,
	viewChild
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SidebarService, UserService } from '../../core/services';
import { SwitchThemeDirective } from '../../shared/directives/switch-theme/switch-theme.directive';
import { NameFirstCharactersPipe } from '../../shared/pipes';

@Component({
	standalone: true,
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	imports: [
		TranslateModule,
		RouterModule,
		NameFirstCharactersPipe,
		SwitchThemeDirective
	]
})
export class SidebarComponent {
	private userService = inject(UserService);
	private sidebarService = inject(SidebarService);
	private isMobile = false;

	private readonly sidebar = viewChild<ElementRef<HTMLDivElement>>('sidebar');
	private readonly opened = this.sidebarService.opened;

	private readonly triggerOpened = computed(() => {
		this.opened();
		this.detectScreenSize();
	});

	private readonly viewModel = computed(() => {
		const user = this.userService.userLogged();
		this.triggerOpened();

		return {
			userLogged: user,
			userFullName: !!user ? `${user.firstName} ${user?.lastName}` : null
		};
	});

	protected get vm() {
		return this.viewModel();
	}

	onLogout() {
		this.userService.logout();
	}

	@HostListener('window:resize')
	private onResize() {
		this.detectScreenSize();
	}

	private detectScreenSize() {
		let collapsed = false;
		this.isMobile = window.innerWidth < 992;

		if (this.isMobile) {
			collapsed = false;
		} else {
			collapsed = !untracked(this.opened);
		}

		const sidebar = untracked(this.sidebar);

		if (sidebar) {
			sidebar.nativeElement.classList.toggle('collapsed', collapsed);
		}
	}
}
