import { Component, OnInit, inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	NavigationEnd,
	Router,
	RouterModule
} from '@angular/router';
import { filter } from 'rxjs';

import { Breadcrumb } from './breadcrumb.model';

@Component({
	selector: 'app-breadcrumb',
	standalone: true,
	imports: [RouterModule],
	templateUrl: './breadcrumb.component.html',
	styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent implements OnInit {
	private router = inject(Router);

	breadcrumbs: Array<Breadcrumb> = [];

	ngOnInit() {
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => {
				const snapshot = this.router.routerState.snapshot;
				this.breadcrumbs = [];
				const breadcrumb = this.getBreadcrumb(snapshot.root);

				breadcrumb.forEach(
					(b, index) => (b.active = index === this.breadcrumbs.length)
				);

				this.breadcrumbs = breadcrumb;
			});
	}

	onNavigateTo(breadcrumb: Breadcrumb) {
		if (!breadcrumb.active) return;
		this.router.navigateByUrl(breadcrumb.url);
	}

	private getBreadcrumb(snapshot: ActivatedRouteSnapshot): Array<Breadcrumb> {
		const result: Array<Breadcrumb> = [];

		for (const child of snapshot.children) {
			const breadcrumb = child.data['breadcrumbs'] ?? [];
			result.push(...breadcrumb);
			result.push(...this.getBreadcrumb(child));
		}

		return result;
	}
}
