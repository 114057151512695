@if (breadcrumbs.length > 0) {
	<div class="breadcrumbs only-desktop-view">
		@for (b of breadcrumbs; track $index) {
			<span
				[class]="b.classList"
				[class.radice]="$index === 0"
				[class.percorso]="$index > 0"
				(click)="onNavigateTo(b)"
			>
				{{ b.label }}
			</span>

			@if (!$last) {
				<i class="bi bi-chevron-right"></i>
			}
		}
	</div>
}
