import { KeyValue } from '@angular/common';

import { WalletBalanceInfo } from '../../../core/services';

export class CardPortfolioItem {
	constructor(
		public imgUrl: string | null,
		public imgTitle: string | null,
		public title: string | null,
		public linkUrl: string | Array<any> | null | undefined,
		public totalBalance: number,
		public walletsBalance: KeyValue<
			string,
			WalletBalanceInfo | null | undefined
		>[]
	) {}
}
