<div class="top-header-mobile only-mobile-view">
	<img
		src="assets/images/logo-full-whitet-.svg"
		alt=""
	/>
</div>
<nav class="top-navbar navbar navbar-expand-lg">
	<span
		class="toggle-sidebar-btn only-desktop-view"
		(click)="onToggleSidebar()"
		><i class="bi bi-arrow-left-right"></i
	></span>
	<div class="top-nav-content">
		<div class="page-infos">
			<app-breadcrumb></app-breadcrumb>

			<!--btn mobile menu-->
			<button
				class="btn only-mobile-view btn-menu-mobile"
				type="button"
				data-bs-toggle="offcanvas"
				data-bs-target="#sidebar"
				aria-controls="sidebar"
			>
				<span class="navbar-toggler-icon"></span>
			</button>
			<!--end-->
			<h2>{{ vm.title }}</h2>
		</div>
		<div class="wallet-info">
			<div class="row">
				@for (option of vm.portfolioOptions; track option) {
					<div
						class="col"
						[class.bg-vertical-line]="!$last"
					>
						<a
							[routerLink]="option.linkUrl"
							routerLinkActive="active"
						>
							<img
								[src]="option.imgUrl"
								alt=""
							/>
							<span
								class="value only-desktop-view ms-1"
								[appToggleHideAmount]="option.totalBalance | number"
							></span>
							<span class="currency only-desktop-view"> {{ vm.preferredCurrency }}</span>
						</a>
					</div>
				}
			</div>
		</div>
		<div class="account-info only-desktop-view">
			<div class="user-menu">
				<span
					class="d-flex align-items-center link-body-emphasis text-decoration-none"
					aria-expanded="false"
				>
					<div class="user-div">
						<p class="user-name">{{ vm.helloUser }}</p>
					</div>
				</span>
			</div>
		</div>
	</div>
</nav>
