<div
	class="offcanvas offcanvas-start sidebar h-100"
	tabindex="-1"
	id="sidebar"
	#sidebar
>
	<!-- Sidebar content -->
	<div class="sidebar-logo-container">
		<a routerLink="/">
			<img
				src="assets/images/logo-mono.svg"
				class="sidebar-logo collapsed-logo logo-mono"
				alt=""
			/>
			<img
				src="assets/images/logo-lettering.svg"
				class="sidebar-logo expanded-logo logo-lettering"
				alt=""
			/>
		</a>
		<button
			type="button"
			class="btn-close only-mobile-view"
			data-bs-dismiss="offcanvas"
			aria-label="Close"
		></button>
	</div>
	<!--logo container-->
	<div class="sidebar-menu-content">
		<div class="sidebar-top-block">
			<ul class="nav nav-pills mb-auto">
				<li class="nav-item">
					<a
						routerLink="/main/portfolio"
						routerLinkActive="active"
						class="nav-link"
						aria-current="page"
					>
						<img
							class="sidebar-icon"
							src="assets/images/portfolio.svg"
							alt=""
						/>
						<span class="title">{{ 'MAIN.THEME.SIDEBAR.PORTOFOLIO' | translate }}</span>
					</a>
				</li>
				<li>
					<a
						routerLink="/main/account-wallets"
						routerLinkActive="active"
						class="nav-link"
					>
						<img
							class="sidebar-icon"
							src="assets/images/account-wallet.svg"
							alt=""
						/>
						<span class="title">{{ 'MAIN.THEME.SIDEBAR.ACCOUNTWALLETS' | translate }}</span>
					</a>
				</li>
				<li>
					<a
						routerLink="/main/goldup-wallets"
						routerLinkActive="active"
						class="nav-link"
					>
						<img
							class="sidebar-icon"
							src="assets/images/goldup-wallet.svg"
							alt=""
						/>
						<span class="title">{{ 'MAIN.THEME.SIDEBAR.GOLDUPWALLETS' | translate }}</span>
					</a>
				</li>
				<li>
					<a
						routerLink="/main/safe"
						routerLinkActive="active"
						class="nav-link"
					>
						<img
							class="sidebar-icon"
							src="assets/images/cassaforte.svg"
							alt=""
						/>
						<span class="title">{{ 'MAIN.THEME.SIDEBAR.SAFE' | translate }}</span>
					</a>
				</li>
				<li>
					<a
						routerLink="/main/pac"
						routerLinkActive="active"
						class="nav-link"
					>
						<img
							class="sidebar-icon"
							src="assets/images/pac.svg"
						/>
						<span class="title">{{ 'MAIN.THEME.SIDEBAR.PAC' | translate }}</span>
					</a>
				</li>
				<li class="border-top my-3"></li>
				<li>
					<a class="nav-link">
						<img
							class="sidebar-icon"
							src="assets/images/collaboratori.svg"
							alt=""
						/>
						<span class="title">{{ 'MAIN.THEME.SIDEBAR.COLLABORATORS' | translate }}</span>
					</a>
				</li>
				<li>
					<a
						href="#"
						class="nav-link"
					>
						<img
							class="sidebar-icon"
							src="assets/images/notifications.svg"
							alt=""
						/>
						<span class="title">{{ 'MAIN.THEME.SIDEBAR.NOTIFICATIONS' | translate }}</span>
						<span class="badge bg-orange">13</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="sidebar-bottom-block">
			<div class="block-pre-footer">
				<a
					routerLink="/main/trading"
					type="button"
					class="btn btn-primary-800 btn-100-center"
				>
					<img
						src="assets/images/trading.svg"
						alt=""
					/>
					<span class="hide-on-collapse ps-2">{{ 'MAIN.THEME.SIDEBAR.TRADING' | translate }}</span>
				</a>
			</div>
			<div class="dropdown user-menu">
				<a
					href="#"
					role="button"
					class="d-flex align-items-center link-body-emphasis text-decoration-none profile-line"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<div class="rounded-circle me-2 profile-img">
						<span>{{ vm.userFullName | nameFirstCharacters }}</span>
					</div>
					<div class="user-div">
						<p class="user-name">{{ vm.userFullName }}</p>

						@if (vm.userLogged?.companyName) {
							<p class="company-name">{{ vm.userLogged?.companyName }}</p>
						}
					</div>
					<i class="bi bi-three-dots-vertical toggle-user-menu only-desktop-view"></i>
				</a>
				<ul class="dropdown-menu text-small shadow">
					<li>
						<a
							class="dropdown-item"
							routerLink="/main/user-profile"
							routerLinkActive="active"
							>{{ 'MAIN.THEME.SIDEBAR.PROFILE' | translate }}</a
						>
					</li>
					<li>
						<a
							class="dropdown-item"
							routerLink="/main/user-settings"
							routerLinkActive="active"
							>{{ 'MAIN.THEME.SIDEBAR.SETTING' | translate }}</a
						>
					</li>
					<li><hr class="dropdown-divider" /></li>
					<li class="theme-switch d-flex justify-content-center">
						<i
							appSwitchTheme
							class="bi bi-brightness-low-fill"
							id="theme-icon"
							role="button"
						></i>
					</li>
					<li>
						<a
							href=""
							class="dropdown-item"
							(click)="onLogout()"
							>{{ 'MAIN.THEME.SIDEBAR.SIGNOUT' | translate }}</a
						>
					</li>
				</ul>
				<ul class="nav nav-pills mb-auto profile-menu-mobile only-mobile-view">
					<li class="border-top"></li>
					<li class="nav-item">
						<a
							class="nav-link"
							routerLink="/main/user-profile"
							routerLinkActive="active"
							aria-current="page"
						>
							<img
								class="sidebar-icon"
								src="assets/images/dot.svg"
								alt=""
							/>
							<span class="title">{{ 'MAIN.THEME.SIDEBAR.PROFILE' | translate }}</span>
						</a>
					</li>
					<li>
						<a
							class="nav-link"
							routerLink="/main/user-settings"
							routerLinkActive="active"
						>
							<img
								class="sidebar-icon"
								src="assets/images/dot.svg"
								alt=""
							/>
							<span class="title">{{ 'MAIN.THEME.SIDEBAR.SETTING' | translate }}</span>
						</a>
					</li>
					<li>
						<a
							href=""
							class="nav-link"
							(click)="onLogout()"
						>
							<img
								class="sidebar-icon"
								src="assets/images/dot.svg"
								alt=""
							/>
							<span class="title">Logout</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
