import { Component, computed, inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	NavigationEnd,
	Router,
	RouterModule
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';

import { SidebarService, UserService } from '../../core/services';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ToggleHideAmountDirective } from '../../shared/directives';
import { CardPortfolioItem } from '../../main/portfolio/card-portfolio';

@Component({
	standalone: true,
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	imports: [
		CommonModule,
		RouterModule,
		BreadcrumbComponent,
		ToggleHideAmountDirective
	]
})
export class HeaderComponent {
	private readonly router = inject(Router);
	private readonly translate = inject(TranslateService);
	private readonly userService = inject(UserService);
	private readonly sidebarService = inject(SidebarService);

	private readonly title$ = this.router.events.pipe(
		filter(event => event instanceof NavigationEnd),
		map(() => this.getTitle(this.router.routerState.snapshot.root))
	);

	private readonly title = toSignal(this.title$, { initialValue: '' });
	private readonly portfolio = this.userService.portfolio;

	private readonly portfolioOptions = computed(
		() =>
			new Array<CardPortfolioItem>(
				new CardPortfolioItem(
					'assets/images/account-wallet.svg',
					this.translate.instant('MAIN.PORTFOLIO.WALLET'),
					'Account',
					['/', 'main', 'account-wallets'],
					this.portfolio().accountBalance,
					this.portfolio().accountWallets
				),
				new CardPortfolioItem(
					'assets/images/goldup-wallet.svg',
					this.translate.instant('MAIN.PORTFOLIO.WALLET'),
					'GoldUp',
					['/', 'main', 'goldup-wallets'],
					this.portfolio().goldupBalance,
					this.portfolio().goldupWallets
				),
				new CardPortfolioItem(
					'assets/images/cassaforte.svg',
					this.translate.instant('MAIN.PORTFOLIO.GOLD_IN'),
					this.translate.instant('MAIN.PORTFOLIO.SAFE'),
					['/', 'main', 'safe'],
					this.portfolio().safeBalance,
					this.portfolio().safe
				)
			)
	);

	private readonly viewModel = computed(() => {
		const user = this.userService.userLogged();
		return {
			title: this.title(),
			helloUser: `${this.translate.instant('MAIN.THEME.HEADER.HELLO')}, ${user?.firstName} ${user?.lastName}`,
			userLogged: user,
			sidebarOpened: this.sidebarService.opened(),
			portfolioOptions: this.portfolioOptions(),
			preferredCurrency: this.userService.preferredCurrency().toUpperCase(),
			hideSales: this.userService.hideSales()
		};
	});

	protected get vm() {
		return this.viewModel();
	}

	onToggleSidebar() {
		this.sidebarService.opened.update(opened => !opened);
	}

	private getTitle(snapshot: ActivatedRouteSnapshot): string {
		let result: string = this.translate.instant(
			'MAIN.THEME.HEADER.TITLE_NO_TITLE'
		);

		for (const [index, child] of snapshot.children.entries()) {
			if (index === snapshot.children.length - 1) {
				result = child.data['title']
					? this.translate.instant(child.data['title'])
					: this.translate.instant('MAIN.THEME.HEADER.TITLE_NO_TITLE');
				if (child.children.length > 0) {
					result = this.getTitle(child);
				}
			}
		}

		return result;
	}
}
