<div class="card card-gup height-static">
	<div class="card-body pe-1">
		<div class="row card-col-container">
			<div class="card-col-1">
				<p class="portfolio-type">
					<img
						[src]="imgUrl()"
						alt=""
					/>
					<span>{{ imgTitle() }}</span>
				</p>
				<h3 class="card-title-gup-2">
					<span>{{ title() }}</span>
				</h3>
				<a
					[routerLink]="linkUrl()"
					class="link-w-icon"
					>{{ 'MAIN.PORTFOLIO.CARD_PORTFOLIO.GO_TO_DETAIL' | translate }}
					<img
						src="assets/images/arrow-up-right.svg"
						alt=""
					/>
				</a>
			</div>
			<div class="card-col-2 card-gup-vertical-line">
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</div>
